import Swiper, {EffectFade, Autoplay, Navigation, Pagination, Thumbs} from "swiper";
import {gsap} from "gsap";
import {isMobile} from './utils';
Swiper.use([EffectFade, Autoplay, Navigation, Pagination]);
import 'swiper/swiper-bundle.min.css';

const productSlider = document.querySelectorAll('.product-image-gallery.swiper');

productSlider.forEach(function (elem, i) {
    const thumbsSlider = new Swiper(elem.parentElement.querySelector(".product-image-gallery-thumbs.swiper"), {
        modules: [
            Thumbs
        ],
        autoHeight: false,
        spaceBetween: 8,
        slidesPerView: 1,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        breakpoints: {
            768: {
                slidesPerView: 'auto',
            },
        }
    });
    new Swiper(elem, {
        spaceBetween: 10,
        slidesPerView: 1,
        thumbs: {
            swiper: thumbsSlider,
        },
    })
})