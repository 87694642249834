document.addEventListener('DOMContentLoaded', function() {
    const form = document.querySelector('.product-variants-form');
    
    const totalUnitsSpan = document.querySelector('.total-units');
    const totalPriceSpan = document.querySelector('.total-price');
    const totalQuantitySpan = document.querySelector('.total-quantity');
    
    function updateTotals() {
        const quantityInputs = form.querySelectorAll('.quantity-input');
        let totalUnits = 0;
        let totalPrice = 0;
        let totalQuantity = 0;
        
        quantityInputs.forEach(input => {
            const quantity = parseInt(input.value) || 0;
            const units = parseInt(input.dataset.units) || 0;
            const price = parseFloat(input.dataset.price) || 0;
            
            totalUnits += units * quantity;
            totalPrice += price * quantity;
            totalQuantity += quantity;
        });
        
        totalUnitsSpan.textContent = totalUnits;
        totalPriceSpan.textContent = formatPrice(totalPrice);
        totalQuantitySpan.textContent = totalQuantity;
    }
    
    function formatPrice(price) {
        return new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD'
        }).format(price);
    }
    
    form.addEventListener('input', function(e) {
        if (e.target.classList.contains('quantity-input')) {
            console.log('input');
            updateTotals();
        }
    });
    
    form.addEventListener('click', function(e) {
        console.log(e.target);
        if (e.target.closest('.quantity-btn')) {
            console.log('click');
            setTimeout(updateTotals, 0);
        }
    });
    
    // Initial calculation
    updateTotals();
});