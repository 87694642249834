import gsap from "gsap";

document.addEventListener('DOMContentLoaded', function() {
    const searchTrigger = document.querySelector('.search-trigger');
    const searchContainer = document.querySelector('.search-container');
    const closeSearchBtn = document.querySelector('#closeSearchBtn');

    searchTrigger.addEventListener('click', function() {
        searchContainer.classList.add('active');
        gsap.to(searchContainer, {duration: 0.3, right: 0});
    });

    closeSearchBtn.addEventListener('click', function() {
        searchContainer.classList.remove('active');
        gsap.to(searchContainer, {duration: 0.3, right: '-100%'});
    });
});