import gsap from 'gsap';

function setupFilterButton() {
    const filterThirdLevelButtons = document.querySelectorAll('.filter-third-level-button');
    const filterThirdLevelModal = document.querySelector('.filter-third-level-modal');
    const modalCloseButton = document.querySelector('.modal-close');
    const clearButton = filterThirdLevelModal.querySelector('.btn.btn-outline');

    if (filterThirdLevelButtons && filterThirdLevelModal) {
        filterThirdLevelButtons.forEach(button => {
            button.addEventListener('click', function () {
                console.log('click');
                filterThirdLevelModal.classList.add('active');
                // Prevent body scroll when modal is open
                document.body.style.overflow = 'hidden';
            });
        });

        // Clear button handler
        clearButton.addEventListener('click', function() {
            const checkboxes = filterThirdLevelModal.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach(checkbox => checkbox.checked = false);
        });

        // Close modal when clicking the close button
        modalCloseButton.addEventListener('click', function() {
            filterThirdLevelModal.classList.remove('active');
            document.body.style.overflow = '';
        });

        // Close modal when clicking outside
        filterThirdLevelModal.addEventListener('click', function(e) {
            const modalContainer = filterThirdLevelModal.querySelector('.modal-container');
            if (!modalContainer.contains(e.target) && e.target !== modalContainer) {
                filterThirdLevelModal.classList.remove('active');
                document.body.style.overflow = '';
            }
        });

        // Close modal on escape key
        document.addEventListener('keydown', function(e) {
            if (e.key === 'Escape' && !filterThirdLevelModal.classList.contains('active')) {
                filterThirdLevelModal.classList.remove('active');
                document.body.style.overflow = '';
            }
        });

        const accordionTriggers = document.querySelectorAll('.accordion-trigger');
    
        accordionTriggers.forEach(trigger => {
            trigger.addEventListener('click', function() {
                const accordionId = this.dataset.accordion;
                const content = document.getElementById(`accordion-${accordionId}`);
                const verticalLine = this.querySelector('.vertical-line');
                
                // Toggle active class for styling
                content.classList.toggle('active');
                verticalLine.classList.toggle('active');
                console.log(trigger);
                
                // Use GSAP to animate height
                if (content.classList.contains('active')) {
                    gsap.to(content, {
                        height: content.scrollHeight,
                        duration: 0.3,
                        ease: "power2.inOut"
                    });
                } else {
                    gsap.to(content, {
                        height: 0,
                        duration: 0.3,
                        ease: "power2.inOut"
                    });
                }
            });
        });
    }
}

// Create a MutationObserver to watch for changes
const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
        if (document.querySelector('.child-categories')) {
            setupFilterButton();
            observer.disconnect(); // Stop observing once we've set up the button
        }
    });
});

// Start observing the document with the configured parameters
observer.observe(document.body, {
    childList: true,
    subtree: true
});